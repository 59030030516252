<template>
  <div class="flex-col shrink-0 section_3">
    <!-- <div class="flex-row items-center group_4 view space-x-12">
      <div class="section_5"></div>
      <span class="font_4">人才推荐</span>
    </div> -->
    <div class="flex-col" v-for="(item, key) in recommends" :key="key">
      <div class="flex-row group_4">
        <div class="flex-col justify-start">
          <span class="font_5">{{ item.name }}</span>
        </div>
      </div>
      <div class="flex-row group_4 space-x-8 item-wrap">
        <!-- tag逗号分割，循环 -->
        <div class="flex-col justify-start items-center"
          v-for="child,inde in item.children" :key="inde" @click="setSearch(child)"
        >
          <span class="font_3">{{ child.name }}</span>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    recommends: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    //setSearch,点击发送搜索事件到父组件
    setSearch(item) {
      console.log("setSearch", item);
      this.$emit("search", item);
      //设置这个item的class，添加一个active class
    },
  },
};
</script>

<style scoped lang="less">
.item-wrap {
  flex-wrap: wrap;
}
.section_3 {
  padding: 1.5rem 1rem 33.75rem;
  background-color: hsl(0, 0%, 100%);
  border-radius: 1rem;
  width: 17.5rem;
  min-height: 86.25rem;
  .group_4 {
    margin-top: 0.88rem;
    padding: 0 0.25rem;
    .section_5 {
      border-radius: 50%;
      width: 0.75rem;
      height: 0.75rem;
      border: solid 0.19rem hsl(233, 88.8%, 61.4%);
    }
    .font_4 {
      font-size: 1.5rem;
      font-family: "PingFang SC";
      line-height: 2.06rem;
      font-weight: 500;
      color: hsl(231, 77.8%, 7.1%);
    }
    .font_5 {
      font-size: 1rem;
      font-family: "PingFang SC";
      line-height: 1.38rem;
      font-weight: 500;
      color: hsl(231, 77.8%, 7.1%);
      word-break: keep-all;
    }
    .text-wrapper_4 {
      // width: 3rem;
    }
    .view_2 {
      margin-left: 0.63rem;
    }
    .text-wrapper_5 {
      margin-left: 1.25rem;
      padding: 0.25rem 0;
      background-color: hsl(0, 0%, 96.9%);
      border-radius: 0.25rem;
      width: 4.75rem;
      height: 1.5rem;
    }
    .view_3 {
      margin-left: 0;
    }
    .text-wrapper_6 {
      padding: 0.25rem 0;
      background-color: hsl(0, 0%, 96.9%);
      border-radius: 0.25rem;
      width: 4rem;
      height: 1.5rem;
    }
    .view_4 {
      margin-left: 0.63rem;
    }
    .view_5 {
      margin-left: 0.63rem;
    }

    .view_6 {
      margin-left: 0.63rem;
    }
    .view_6 {
      margin-left: 0.63rem;
    }
    .view_7 {
      margin-left: 0.63rem;
    }
    .view_8 {
      margin-left: 0.63rem;
    }
    .view_9 {
      margin-left: 0.63rem;
    }
    .view_10 {
      margin-left: 0.63rem;
    }
    .view_12 {
      margin-left: 0.63rem;
    }
  }
  .view {
    margin-top: 0;
  }
  .space-x-12 {
    & > *:not(:first-child) {
      margin-left: 0.75rem;
    }
  }
  .space-x-8 {
    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  .divider {
    margin: 0.88rem 0.25rem 0;
    background-color: hsla(0, 0%, 0%, 0.1);
    height: 0.063rem;
  }
  .group_12 {
    margin-top: 0.88rem;
  }
  .text-wrapper_7 {
    padding: 0.25rem 0;
    background-color: hsl(0, 0%, 96.9%);
    border-radius: 0.25rem;
    width: 2.5rem;
    height: 1.5rem;
    margin-bottom: 0.25rem;
  }
  .font_3 {
    font-size: 0.75rem;
    font-family: "PingFang SC";
    line-height: 1.06rem;
    color: hsla(231, 77.8%, 7.1%, 0.65);
  }
  .text-wrapper_8 {
    padding: 0.25rem 0;
    background-color: hsl(0, 0%, 96.9%);
    border-radius: 0.25rem;
    width: 3.25rem;
    height: 1.5rem;
  }
}
</style>