<template>
  <div class="flex-col page">
    <Header :showSearch="false" :activeNav="0"/>
    <div style="margin-bottom: 2rem;">
      <div class="flex-row self-start group_10 item-wrap" v-if="resultData.length>0">
        <div class="flex-row self-start group_10 item-wrap" style="width: 100%">
          <div @click="clickItem(rem)" class="flex-col section_5 space-y-32 c-cursor view_1"
               v-for="(rem,remkey) in resultData" :key="remkey"
               v-if="rem.title" :title="rem.title">
            <div class="flex-col items-start space-y-12">
              <img class="image_4" :src="rem.img" />
              <span class="font_4 text_6">{{ rem.title }}</span>
            </div>
          </div>
        </div>
        <pageItem
          :paginations="{...paginations}"
          @changepage="changepage"
          @pageSizeChange="pageSizeChange"
          v-if="resultData.length>0"
          class="custompage"
        />
      </div>
      <Alert show-icon v-if="resultData.length==0" style="margin: 2rem">
        提示：
        <template #icon>
          <Icon type="ios-bulb-outline"></Icon>
        </template>
        <template #desc>暂未查到结果！ </template>
      </Alert>
    </div>

  </div>
</template>

<script>
  import Header from '../../components/header/header.vue';
  import pageItem from "../../components/common/PageItem";

  export default {
    components: { Header,pageItem },
    data() {
      return {
          paginations: {
              // 初始化信息总条数
              total: 15,
              // 每页显示多少条
              pageSize: 20,
              // 每页条数切换的配置
              pageSizeOpts: [20, 30, 45, 60, 75],
              current: 1, //当前位于哪页
              showTotal: true
          },
          type:this.$route.params.type,
          category_id:this.$route.params.cid,
          resultData:{}
      };
    },
    created() {
        this.getPageList();
    },
    mounted() {

    },
    methods: {
        clickItem(item) {
            if(this.type == 'normal'){
                if(item.type == 1){
                    window.open(item.link, '_blank');
                }else{
                    this.$router.push({name: 'listdetails',params: { type:this.type,detailid: item.id }});
                }
            }else{
                this.$router.push({name: 'listdetails',params: { type:this.type,detailid: item.id }});
            }
        },
        getPageList(pageIndex = 1, pageSize = 20) {
            console.log('this.type',this.type);
            var params = {
                limit:pageSize,
                page:this.paginations.current,
                type:this.type,
                category_id:this.category_id
            };
            this.http.post(this.api.moreList, params).then((res) => {
              let {code, data} = res.data;
              if (code == 200) {
                  console.log("resultData", data)
                  this.resultData = data.data;
                  this.paginations.total = data.total;
              } else {

              }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        //切换当前页
        changepage(page) {
            this.paginations.current = page;
            this.getPageList(page, this.paginations.pageSize);
        },
        //切换每页条数时的回调，返回切换后的每页条数
        pageSizeChange(page_size) {
            this.paginations.current = 1; //当前页
            this.paginations.pageSize = page_size; //所点击的条数,传给一页要显示多少条
            this.getPageList(this.paginations.current, page_size);
        },
    },
  };
</script>

<style scoped lang="less">
.item-wrap {
  flex-wrap: wrap;
}
.page {
  background: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .group_10 {
    max-width: 100rem;
    margin: 0 auto;
    margin-top: 2rem;
    // width: 55.88rem;
    .view_1 {
      width: calc(25% - 1.5rem);
      // flex: 1 1 17.63rem;
    }

    .section_5 {
      margin-right: 1.5rem;
      background-color: hsl(0, 0%, 96.9%);
      border-radius: 1rem;
      margin-bottom: 1.5rem;
    }
    .space-y-12 {
      & > *:not(:first-child) {
        margin-top: 0.75rem;
      }

      .image_4 {
        border-radius: 1rem 1rem 0px 0px;
        // width: 17.63rem;
        width: 100%;
        height: auto;
      }

      .font_4 {
        font-size: 0.88rem;
        font-family: 'PingFang SC';
        line-height: 1.25rem;
        color: hsl(231, 77.8%, 7.1%);
        padding: 0 0.4rem;
        margin: 0.5rem auto;
      }
    }
    .custompage{
      text-align: center;
      width: 100%;
    }
  }
}
@media (max-width: 800px) {
  .page .section_4 .group_10 .view_1{
    width: calc(50% - 1.5rem);
  }
}
</style>
