<template>
  <div
    @click="clickItem(obj)"
    class="flex-col section_5 space-y-32 c-cursor"
    :title="obj.name"
  >
    <div class="flex-col items-start space-y-12">
      <img class="image_4" :src="obj.cover" />
      <span class="font_4 text_6">{{ obj.name }}</span>
    </div>
    <!--    <span class="self-start font_5 text_9">{{ obj.author }}</span>-->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    obj: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      temp_domain: "",
    };
  },
  mounted() {
    this.temp_domain = this.domain;
    console.log("this.temp_domain", this.temp_domain);
  },
  methods: {
    clickItem(item) {
      console.log("clickItem", item);
      // 如果存在file字段
      if (item.hasOwnProperty("file")) {
        //如果包含file_ext字段，说明是文件
        if (item.file != undefined && item.file != null) {
          //说明是培训风采类型的内容
          if (item.type == "file") {
            console.log("file", item);
            //说明是文件
            this.$router.push({
              name: "trainfile",
              params: { id: item.id },
            });
          } else {
            console.log("folder", item);
            //如果id=5，是培训成效，跳转effect
            if (item.id == 5) {
              this.$router.push({
                name: "traineffect",
              });
            } else {
              //说明是目录,跳转到目录页面
              this.$router.push({
                name: "trainfolder",
                params: { id: item.id },
              });
            }
          }
        }
      } else {
        if (item.type == 1) {
          window.open(item.link, "_blank");
        } else {
          this.$router.push({
            name: "coursedetails",
            params: { detailid: item.id },
          });
        }
      }
      // //如果包含file_ext字段，说明是文件
      // if (item.file.length>0 && item.file!=undefined && item.file!=null) {
      //   //说明是培训风采类型的内容
      //   if(item.type=='file'){
      //     //说明是文件
      //     this.$router.push({
      //       name: "file",
      //       params: { id: item.id },
      //     });
      //   }else{
      //     //说明是目录,跳转到目录页面
      //     this.$router.push({
      //       name: "folder",
      //       params: { id: item.id },
      //     });
      //   }
      // } else {
      //   if (item.type == 1) {
      //     window.open(item.link, "_blank");
      //   } else {
      //     this.$router.push({
      //       name: "coursedetails",
      //       params: { detailid: item.id },
      //     });
      //   }
      // }
    },
  },
};
</script>

<style scoped lang="less">
.section_5 {
  padding-bottom: 0;
  background-color: hsl(0, 0%, 96.9%);
  border-radius: 1rem;
  height: auto;
  .space-y-12 {
    & > *:not(:first-child) {
      margin-top: 0.75rem;
    }
    .image_4 {
      border-radius: 1rem 1rem 0px 0px;
      // width: 17.63rem;
      width: 100%;
      height: auto;
      max-height: 172.28px;
    }
    .font_4 {
      font-size: 0.88rem;
      font-family: "PingFang SC";
      line-height: 1.25rem;
      color: hsl(231, 77.8%, 7.1%);
      padding: 0 0.4rem;
    }
    .text_6 {
      margin: 0.5rem auto;
    }
  }
  .font_5 {
    font-size: 0.75rem;
    font-family: "PingFang SC";
    line-height: 1.06rem;
    color: hsla(231, 77.8%, 7.1%, 0.45);
  }
  .text_9 {
    margin-left: 1rem;
  }
}
.space-y-32 {
  & > *:not(:first-child) {
    margin-top: 2rem;
  }
}
</style>
