<template>
  <div class="flex-col page">
    <Header :showSearch="true" :activeNav="0" />
    <div class="group_3 newsinfo">
      <div class="header">
        <div class="title">{{ details.title }}</div>
        <!--        <div class="info"><span v-if="details.author">作者：{{details.author}}</span><span>时间：{{details.created_at}}</span></div>-->
      </div>
      <div class="content">
        <!-- 循环imgArrs，渲染轮播图，使用iview -->
        <Carousel v-model="value" style="height: auto;width:60vw;max-height: 800px;">
          <CarouselItem v-for="(item, index) in imgArrs" :key="index">
            <img :src="item" alt="" style="width: 100%; " />
          </CarouselItem>
        </Carousel>
        <!-- <p class="article_text_content" v-html="details.content"></p> -->
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header/header.vue";
import HotFilters from "../../components/HotFilters/HotFilters.vue";

export default {
  components: { Header, HotFilters },
  data() {
    return {
      detailid: this.$route.params.detailid,
      type: this.$route.params.type,
      details: "",
      imgArrs:[],
      value:0
    };
  },
  created() {
    
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      var params = {
        type: this.type,
        id: this.detailid,
      };
      this.http
        .post(this.api.listDetails, params)
        .then((res) => {
          let { code, data } = res.data;
          this.details = "";
          if (code == 200) {
            this.details = data;
            //提取conteng中的图片url，放到数组中
            let imgArr = [];
            let reg = /<img.*?(?:>|\/>)/gi;
            let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
            let arr = this.details.content.match(reg);
            if (arr) {
              for (let i = 0; i < arr.length; i++) {
                let src = arr[i].match(srcReg);
                // 获取图片地址
                if (src[1]) {
                  imgArr.push(src[1]);
                }
              }
            }
            this.imgArrs = imgArr;
            console.log("getDetails data",  this.imgArrs);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="less">
.item-wrap {
  flex-wrap: wrap;
}
.page {
  background-color: hsl(216, 23.8%, 95.9%);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .newsinfo {
    max-width: 75rem;
    margin: 2rem auto;
  }
  .header {
    font-size: 1.5rem;
    font-family: PingFang SC;
    line-height: 2.06rem;
    font-weight: 600;
    color: #040820;
    text-align: center;
    padding: 0 1rem;
    .info {
      color: #999;
      font-size: 1rem;
      margin: 1rem auto;
      text-align: center;
      span {
        padding: 0 2rem;
      }
    }
  }
  .content {
    width: 100%;
    max-width: 100%;
    font-size: 1.2rem;
    padding: 1rem;
  }
}
</style>
