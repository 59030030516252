<template>
  <div>
    <div class="flex-col page">
      <Header
        :cStyle="'background-color:transparent;z-index:10;'"
        :showSearch="false"
        :activeNav="0"
      />

      <img
        class="image-arc"
        src="../../assets/99e8d65ea1b3b4648a00e9ce25f5790a.png"
      />

      <div class="flex-col relative section">
        <!--<van-field
          class="inputValue_iMl"
          placeholder="搜索课程，教师昵称"
          :border="false"
          label-width="100%"
          input-align="left"
          v-model="search_TQC"
        >
          <template #button>
            <van-button size="small" type="default">搜索</van-button>
          </template>
        </van-field>-->
        <div class="flex-row group_2">
          <CategoryNavigate class="group_1" />
          <van-swipe
            class="swiper-jlzkuY6U"
            :autoplay="5000"
            indicator-color="white"
          >
            <van-swipe-item
              :height="'288'"
              v-for="(image, index) in 1"
              :key="index"
            >
              <img class="banner-img" src="../../assets/banner.png" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <div class="flex-col relative section_4">
        <div class="section_4_line">
          <div class="section_4_ll">
            <div
              v-for="(item, key) in indexArr"
              :key="key"
              style="margin-bottom: 2rem"
            >
              <div class="flex-row justify-between items-center">
                <div class="text_4" :id="item.category.name">
                  <div class="section_5"></div>
                  {{ item.category.name }}
                </div>
                <div
                  class="flex-row items-center space-x-6" v-if="key==0"
                  @click="clickTolist('normal', item.category.id)"
                  :title="'更多' + item.category.name"
                >
                  <span class="font_1 text_5">更多</span>
                  <img
                    class="shrink-0 image_3"
                    src="../../assets/110469012fc3630347578fd13e1a3a06.png"
                  />
                </div>
                <div
                  class="flex-row items-center space-x-6" v-else
                  @click="clickToTrain()"
                  :title="'更多' + item.category.name"
                >
                  <span class="font_1 text_5">更多</span>
                  <img
                    class="shrink-0 image_3"
                    src="../../assets/110469012fc3630347578fd13e1a3a06.png"
                  />
                </div>
              </div>
              <div class="flex-row self-start group_10 item-wrap">
                <Course
                  v-for="(itemCourse, keyCourse) in item.course"
                  :key="keyCourse"
                  :obj="itemCourse"
                  class="view_1"
                />
              </div>
            </div>
          </div>
          <div class="section_4_rr" v-if="fivemedia.length > 0">
            <div style="margin-bottom: 2rem">
              <div class="flex-row justify-between items-center">
                <div class="text_4" id="媒体宣传">
                  <div class="section_5"></div>
                  媒体宣传
                </div>
                <div
                  class="flex-row items-center space-x-6"
                  @click="clickTolist('media')"
                  title="更多媒体宣传"
                >
                  <span class="font_1 text_5">更多</span>
                  <img
                    class="shrink-0 image_3"
                    src="../../assets/110469012fc3630347578fd13e1a3a06.png"
                  />
                </div>
              </div>
              <div class="group_10 item-wrap">
                <div
                  class="media-item"
                  @click="toNewInfo(media.id)"
                  v-for="(media, inkey) in fivemedia"
                  :key="inkey"
                >
                  <div class="title" :title="media.title" style="font-size: 18px;">
                    {{ media.title }}
                  </div>
                  <!--                  <div class="intro">{{media.intro}}</div>-->
                </div>
                <div v-if="showmore" style="text-align: center">
                  <div class="more items-center" @click="showMoreData">
                    更多新闻
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="margin-bottom: 2rem">
          <div class="flex-row justify-between items-center">
            <div class="text_4" id="精彩回忆">
              <div class="section_5"></div>
              精彩回忆
            </div>
            <div
              class="flex-row items-center space-x-6"
              title="更多精彩回忆"
              @click="clickTolist('remmber')"
            >
              <span class="font_1 text_5">更多</span>
              <img
                class="shrink-0 image_3"
                src="../../assets/110469012fc3630347578fd13e1a3a06.png"
              />
            </div>
          </div>
          <div class="flex-row self-start group_10 item-wrap">
            <div
              class="flex-row self-start group_10 item-wrap"
              style="width: 100%"
            >
              <!-- 循环remmber，然后imgs是数组对应CarouselItem -->
              <Carousel
                @on-click="clickItem(rem)"
                loop
                v-for="(rem, remkey) in remmber"
                :key="remkey"
                class="flex-col section_5 space-y-32 view_2"
                v-show="rem.imgs.length > 0"
              >
                <CarouselItem v-for="(item, index) in rem.imgs" :key="index">
                  <div class="flex-col items-start space-y-12">
                    <img
                      class="image_4"
                      :src="item"
                      style="width: 100%; height: 100%"
                    />
                    <span class="font_4 text_6">{{ rem.title }}</span>
                  </div>
                </CarouselItem>
              </Carousel>

              <!-- <Carousel loop v-for="(rem, remkey) in remmber" :key="remkey">
                <CarouselItem @click="clickItem(rem)">
                  <div class="flex-col items-start space-y-12">
                    <img class="image_4" :src="rem.imgs" />
                    <span class="font_4 text_6">{{ rem.title }}</span>
                  </div>
                </CarouselItem>
              </Carousel> -->
              <!-- old -->
              <!-- <div @click="clickItem(rem)" class="flex-col section_5 space-y-32 c-cursor view_2"
                   v-for="(rem,remkey) in remmber" :key="remkey"
                  v-if="rem.title" :title="rem.title">
                <div class="flex-col items-start space-y-12">
                  <img class="image_4" :src="rem.img" />
                  <span class="font_4 text_6">{{ rem.title }}</span>
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <div style="margin-bottom: 2rem">
          <div class="flex-row justify-between items-center">
            <div class="text_4">
              <div class="section_5"></div>
              友情链接
            </div>
          </div>
          <div class="self-start group_10 item-wrap">
            <span
              class="friendlink"
              v-for="(item, index) in freindData"
              :key="index"
            >
              <a :href="item.href" target="blank">{{ item.name }}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryNavigate from "../../components/categoryNavigate/categoryNavigate.vue";
import Course from "../../components/course/course.vue";
import Header from "../../components/header/header.vue";

export default {
  components: { CategoryNavigate, Course, Header },
  data() {
    return {
      search_TQC: "",
      inputValue_iMl: "",
      indexArr: [],
      remmber: [],
      freindData: [],
      mediaData: [],
      fivemedia: [],
      showmore: false,
    };
  },
  created() {
    this.getIndex();
    this.getRemmber();
    this.getFreind();
    this.getMedia();
  },
  mounted() {},
  methods: {
    clickToTrain(){
        this.$router.push({ name: "train" });
    },
    clickTolist(type, id = 0) {
      if (type == "media") {
        this.$router.push({
          name: "newslist",
          params: { type: type, cid: id },
        });
      } else {
        this.$router.push({ name: "list", params: { type: type, cid: id } });
      }
    },
    clickItem(item) {
      console.log("clickItem", item);
      // this.$router.push({
      //   name: "remmberdetails",
      //   params: { detailid: item.id },
      // });
      // this.$router.push({
      //   name: "listdetails",
      //   params:{type:'remmber',detailid:item.id}
      // });

      //跳转到trainfolder
      this.$router.push({
        name: "trainfolder",
        params: { id: 6 },
      });
    },
    showMoreData() {
      this.fivemedia = this.mediaData;
      this.showmore = false;
    },
    getIndex() {
      var params = {};
      this.http
        .post(this.api.index, params)
        .then((res) => {
          let { code, data } = res.data;
          this.indexArr = [];
          if (code == 200) {
            //console.log("getIndex data", data)
            this.indexArr = data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRemmber() {
      var params = {};
      this.http
        .post(this.api.getRemmber, params)
        .then((res) => {
          let { code, data } = res.data;
          this.remmber = [];
          if (code == 200) {
            //console.log("getIndex data", data)
            this.remmber = data;
            //循环处理content字段，正则提取图片url到imgArrs字段
            this.remmber.forEach((item) => {
              let imgArr = [];
              let reg = /<img.*?(?:>|\/>)/gi;
              let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
              let arr = item.content.match(reg);
              if (arr) {
                for (let i = 0; i < arr.length; i++) {
                  let src = arr[i].match(srcReg);
                  // 获取图片地址
                  if (src[1]) {
                    imgArr.push(src[1]);
                  }
                }
              }
              item.imgs = imgArr;
            });
            console.log("remmber", this.remmber);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFreind() {
      var params = {};
      this.http
        .post(this.api.getFreind, params)
        .then((res) => {
          let { code, data } = res.data;
          this.freindData = [];
          if (code == 200) {
            // console.log("getfreind data", data)
            this.freindData = data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMedia() {
      var params = {};
      this.http
        .post(this.api.getMedia, params)
        .then((res) => {
          let { code, data } = res.data;
          this.mediaData = [];
          if (code == 200) {
            console.log("mediaData", data);
            this.mediaData = data;
            if (this.mediaData.length > 5) {
              this.showmore = true;
              for (var i = 0; i <= 8; i++) {
                this.fivemedia[i] = data[i];
              }
            } else {
              this.fivemedia = this.mediaData;
            }
            console.log("fivemedia", this.fivemedia);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClick() {
      this.$router.push({ name: "jobs" });
    },
    toNewInfo(id) {
      this.$router.push({ name: "news", params: { newId: id } });
    },

    onSearch_TQC(event) {
      console.log("search: ", event);
    },
  },
};
</script>

<style scoped lang="less">
.section ::v-deep .van-swipe__track,
.section ::v-deep .van-swipe-item {
  width: 100% !important;
}

.item-wrap {
  flex-wrap: wrap;
}

.section ::v-deep .banner-img {
  height: 100%;
  width: 100%;
}

.section .inputValue_iMl {
  height: 64px;
  padding: 5px 5px 5px 1.5rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #fff;
  border-radius: 12px;
  width: 70%;
  margin: 0 auto;
}

.section ::v-deep .van-field__value,
.section ::v-deep .van-field__body,
.section ::v-deep .van-field__button,
.section ::v-deep .van-button--small {
  height: 100%;
}

.section ::v-deep .van-field__control {
  color: #fff;
}

.section ::v-deep .van-button--default {
  color: #455af4;
  font-size: 14px;
  background: #fff;
  width: 100px;
  border-radius: 8px;
}

.page {
  // background-color: hsl(0, 0%, 100%);
  background-color: hsl(233, 88.8%, 61.4%);
  // height: 100%;
  // width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  .image-arc {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    height: 400px;
    width: 285px;
  }

  .section {
    padding: 0 7.5rem 2.5rem;
    // background-color: hsl(233, 88.8%, 61.4%);
    background-color: transparent;
    z-index: 1;

    .group {
      padding: 2.5rem 0 2.94rem;
      width: 36.5rem;

      .image {
        width: 17.5rem;
        height: 3.25rem;
      }

      .space-x-64 {
        & > *:not(:first-child) {
          margin-left: 4rem;
        }
      }
    }

    .section_2 {
      align-self: center;
    }

    .search_TQC {
      background-color: hsla(0, 0%, 100%, 0.1);
      border: solid 0.063rem hsl(0, 0%, 100%);
      border-radius: 0.75rem;
      width: 50rem;
      height: 4rem;
      overflow: hidden;
      padding: 0.44rem 0.5rem;

      & .van-search__content {
        padding-left: unset;
        background-color: unset;
        border-radius: unset;
        align-items: center;
      }

      & .van-cell {
        line-height: 1;
        padding: 0;
        margin: 0;
      }
    }

    .group_2 {
      flex-wrap: wrap;
      flex-direction: row;

      .group_1 {
        flex-shrink: 0;
      }

      .swiper-jlzkuY6U {
        // width: calc(100% - 1.69rem);
        // flex: 1 1 auto;
        flex: 1;
        // margin-left: 1.69rem;
        // width: 58.81rem;
        height: 18rem;
        background-color: hsl(233, 88.8%, 61.4%);
        min-width: 400px;
      }
    }

    .text-wrapper {
      padding: 3.25rem 0 20rem;
      background-image: url("../../assets/99e8d65ea1b3b4648a00e9ce25f5790a.png");
      background-position: 0% 0%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 18.13rem;
    }

    .pos {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .section_4 {
    // margin-top: -13.38rem;
    padding: 2rem 7.5rem 3.38rem;
    background-color: hsl(0, 0%, 100%);
    border-radius: 0px 6.25rem 0px 0px;

    .text_4 {
      //color: hsl(231, 77.8%, 7.1%);
      color: #040820;
      font-size: 1.6rem;
      font-family: "PingFang SC";
      font-weight: 600;
      line-height: 2.06rem;

      .section_5 {
        display: inline-block;
        border-radius: 50%;
        width: 0.75rem;
        height: 0.75rem;
        border: solid 0.19rem hsl(233, 88.8%, 61.4%);
        vertical-align: middle;
      }
    }

    .space-x-6 {
      & > *:not(:first-child) {
        margin-left: 0.38rem;
      }

      .text_5 {
        cursor: pointer;
        color: hsl(231, 77.8%, 7.1%);
      }

      .image_3 {
        width: 1rem;
        height: 1rem;
      }
    }
    .swiper-remmber {
      width: 100%;
      img {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 30rem;
      }
      .video-box {
        max-height: 40rem;
        max-width: 100%;
      }
    }
    .group_10 {
      margin-top: 2rem;
      // width: 55.88rem;
      .view_1 {
        width: calc(33.33% - 1.5rem);
        // flex: 1 1 17.63rem;
      }
      .view_2 {
        width: calc(25% - 1.5rem);
      }

      .section_5 {
        margin-right: 1.5rem;
        background-color: hsl(0, 0%, 96.9%);
        border-radius: 1rem;
        margin-bottom: 1.5rem;

        .font_5 {
          font-size: 0.75rem;
          font-family: "PingFang SC";
          line-height: 1.06rem;
          color: hsla(231, 77.8%, 7.1%, 0.45);
        }

        .text_9 {
          margin-left: 1rem;
        }
      }

      .view_5 {
        flex: 1 1 17.63rem;
      }

      .space-y-12 {
        & > *:not(:first-child) {
          margin-top: 0.75rem;
        }

        .image_4 {
          border-radius: 1rem 1rem 0px 0px;
          // width: 17.63rem;
          width: 100%;
          height: auto;
        }

        .font_4 {
          font-size: 0.88rem;
          font-family: "PingFang SC";
          line-height: 1.25rem;
          color: hsl(231, 77.8%, 7.1%);
          padding: 0 0.4rem;
          margin: 0.5rem auto;
        }

        .text_7 {
          text-align: left;
        }

        .text_8 {
          text-align: left;
        }
      }

      .view_6 {
        flex: 1 1 17.63rem;
      }
      .friendlink {
        padding-right: 2rem;
        font-size: 1.2rem;
        a {
          color: #333;
          &:hover {
            color: hsl(233, 88.8%, 61.4%);
          }
        }
      }
    }

    .section_6 {
      padding: 1rem 0;
      background-color: hsla(233, 88.8%, 61.4%, 0.09);
      border-radius: 0.5rem;
      width: 32.5rem;
      border: solid 0.063rem hsla(233, 88.8%, 61.4%, 0.45);
      margin: 1.94rem auto 0;

      .font_2 {
        font-size: 0.88rem;
        font-family: "PingFang SC";
        line-height: 1.25rem;
        color: hsla(0, 0%, 100%, 0.65);
      }

      .text_10 {
        margin-left: 12.25rem;
        color: hsl(233, 88.8%, 61.4%);
      }

      .image_2 {
        width: 0.75rem;
        height: 0.75rem;
      }

      .image_5 {
        margin-right: 12.38rem;
      }
    }

    .space-x-4 {
      & > *:not(:first-child) {
        margin-left: 0.25rem;
      }
    }
    .section_4_line {
      width: 100%;
      .section_4_ll {
        width: 70%;
        float: left;
        padding-right: 2%;
      }
      .section_4_rr {
        width: 30%;
        float: left;
        padding-left: 2%;
        .group_10 {
          text-align: left;
        }
        .media-item {
          margin-top: 2rem;
          max-width: 100%;
          cursor: pointer;
          .title {
            font-size: 1.2rem;
            color: #040820;
            &:hover {
              color: hsl(233, 88.8%, 61.4%);
            }
          }
          .intro {
            font-size: 1.2rem;
            color: rgb(11 14 31 / 65%);
            margin-top: 0.2rem;
          }
        }
        .more {
          padding: 0.3rem 1rem;
          border: 1px solid #515a6e;
          display: inline-block;
          margin: 0 auto;
          cursor: pointer;
          margin-top: 1rem;
          &:hover {
            color: hsl(233, 88.8%, 61.4%);
            border-color: hsl(233, 88.8%, 61.4%);
          }
        }
      }
    }
  }

  .font_1 {
    font-size: 1.25rem;
    font-family: "PingFang SC";
    line-height: 1.75rem;
    color: hsl(0, 0%, 100%);
  }
}

@media (min-width: 1610px) {
  .section .inputValue_iMl {
    width: 65%;
  }

  .page .section_4 .group_10 .view_1 {
    width: calc(33.33% - 1.5rem);
  }
  .page .section_4 .group_10 .view_2 {
    width: calc(25% - 1.5rem);
  }

  .page .section .group_2 .swiper-jlzkuY6U {
    height: 22rem;
  }
}

@media (min-width: 1910px) {
  .section .inputValue_iMl {
    width: 65%;
  }

  .page .section_4 .group_10 .view_1 {
    width: calc(33.33% - 1.5rem);
  }
  .page .section_4 .group_10 .view_2 {
    width: calc(25% - 1.5rem);
  }

  .page .section .group_2 .swiper-jlzkuY6U {
    height: 24rem;
  }

  .page .section {
    padding: 0 10.5rem 2.5rem;
  }

  .page .section_4 {
    padding: 2rem 10.5rem 3.38rem;
  }
}

@media (min-width: 2010px) {
  .section .inputValue_iMl {
    width: 75%;
  }

  .page .section_4 .group_10 .view_1 {
    width: calc(33.33% - 1.5rem);
  }
  .page .section_4 .group_10 .view_2 {
    width: calc(25% - 1.5rem);
  }

  .page .section .group_2 .swiper-jlzkuY6U {
    height: 26rem;
  }

  .page .section {
    padding: 0 14.5rem 2.5rem;
  }

  .page .section_4 {
    padding: 2rem 14.5rem 3.38rem;
  }
}

@media (max-width: 1130px) {
  .section .inputValue_iMl {
    width: 100%;
  }

  .page .section_4 .group_10 .view_1 {
    width: calc(33.3333% - 1.5rem);
  }
  .page .section_4 .group_10 .view_2 {
    width: calc(25% - 1.5rem);
  }

  // .page .section .group_2 .swiper-jlzkuY6U {
  //   height: 20rem;
  // }
}

@media (max-width: 800px) {
  .page .section {
    padding: 0 4.5rem 2.5rem;
  }

  .page .section_4 .group_10 .view_1,
  .page .section_4 .group_10 .view_2 {
    width: calc(50% - 1.5rem);
  }

  .page .section_4 .section_6 {
    width: 100%;
  }

  .page .section_4 {
    padding: 2rem 3.5rem 3.38rem;
    .section_4_line {
      width: 100%;
      .section_4_ll {
        width: 100%;
        float: none;
        padding-right: 0;
      }
      .section_4_rr {
        width: 100%;
        float: none;
        padding-left: 0;
      }
    }
  }
}
</style>
