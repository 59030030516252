<template>
  <div class="flex-col page">
    <Header :showSearch="true" :activeNav="0"/>
    <div class="group_3 newsinfo">
      <div class="header">
        <div class="title">{{mediaDetails.title}}</div>
        <div class="info"><span v-if="mediaDetails.author">作者：{{mediaDetails.author}}</span><span>时间：{{mediaDetails.created_at}}</span></div>
      </div>
      <div class="content">
        <p class="article_text_content" v-html="mediaDetails.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from '../../components/header/header.vue';
  import HotFilters from '../../components/HotFilters/HotFilters.vue';

  export default {
    components: { Header, HotFilters },
    data() {
      return {
        newId:this.$route.params.newId,
        mediaDetails: ''
      };
    },
    created() {
        this.getNewsInfo();
    },
    mounted() {

    },
    methods: {
        getNewsInfo() {
            var params = {
                id:this.newId
            };
            this.http.post(this.api.mediaDetails, params).then((res) => {
              let {code, data} = res.data;
              this.mediaDetails = '';
              if (code == 200) {
                  console.log("mediaDetails", data)
                  this.mediaDetails = data;

                  setTimeout(() => {
                    //设置article_text_content下所有的img的hspace=(100%-img.width)/2
                  let imgs = document.querySelectorAll('.article_text_content img');
                  console.log(imgs);
                  imgs.forEach((img) => {
                    let imgWidth = img.width;
                    let parentWidth = img.parentNode.clientWidth;
                    let hspace = (parentWidth - imgWidth) / 2;
                    img.style.marginLeft = hspace + 'px';
                    img.style.marginRight = hspace + 'px';
                  });
                  }, 1000);
              } else {

              }
            })
            .catch((error) => {
                console.log(error);
            });
        },
    },
  };
</script>

<style scoped lang="less">
.item-wrap {
  flex-wrap: wrap;
}
.page {
  background-color: hsl(216, 23.8%, 95.9%);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .newsinfo{
    max-width: 75rem;
    margin: 2rem auto;
  }
  .header{
    font-size: 1.5rem;
    font-family: PingFang SC;
    line-height: 2.06rem;
    font-weight: 600;
    color: #040820;
    text-align: center;
    padding: 0 1rem;
    .info{
      color: #999;
      font-size: 1rem;
      margin: 1rem auto;
      text-align: center;
      span{
        padding:0 2rem;
      }
    }
  }
  .article_text_content{
    // 段落首行悬空，照片居中平铺
    text-indent: 2em;
    // text-align: center;
    // 照片居中平铺
    img{
      display: block;
      margin: 0 auto !important;
      // width: 50%;
    }
  }
  .content{
    width: 100%;
    max-width: 100%;
    font-size: 1.2rem;
    padding: 1rem;
  }
}
</style>
