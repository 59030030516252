<template>
  <div class="flex-col page">
    <Header :showSearch="true" :activeNav="0" />
    <div class="article">培训成效</div>
    <div class="section">
      <div class="title">产教融合科教研成效初见端倪</div>
      <div class="desc">
        专业群利用专业优势和资源优势
        向广州市从化区罗洞村、新村村、风二村重点人群开展新媒体运营的新技术，获得当地村委村政府一致好评。
      </div>
      <div class="items">
        <!-- 获取感谢信和心得数据 -->
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 20px;
            padding: 20px;
            width: 100%;
          "
          class="flex-row self-start group_10 item-wrap"
        >
          <div
            v-for="(item, ind) in thanks"
            :key="ind"
            @click="toPage(item)"
            class="flex-col section_5 space-y-32 c-cursor"
            style="
              padding: 10px;
              background-color: white;
              flex: 1 0 calc(33.33% - 20px);
              max-width: 300px;
            "
          >
            <div class="flex-col items-start space-y-12">
              <img class="image_4" :src="item.file" style="width: 100%" />
              <span class="font_4 text_6">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title">
        提升学员技能水平，推动当地产业升级，促进就业率提高
      </div>
      <div class="desc">
        专业群紧密结合乡村振兴战略和创新驱动战略，立足当地实际和市场需要，着眼于新媒体运营技术水平、以乡村特色产业经营管理能力和高效化就业创业能力的提高为目的展开培训，培训学员取得相关技能证书
      </div>
      <div class="items">
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 20px;
            padding: 20px;
            width: 100%;
          "
          class="flex-row self-start group_10 item-wrap"
        >
          <div
            v-for="(item, ind) in certificates"
            :key="ind"
            @click="toPage(item)"
            class="flex-col section_5 space-y-32 c-cursor"
            style="
              padding: 10px;
              background-color: white;
              flex: 1 0 calc(33.33% - 20px);
              max-width: 300px;
            "
          >
            <div class="flex-col items-start space-y-12">
              <img class="image_4" :src="item.file" style="width: 100%" />
              <span class="font_4 text_6">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title">
        全媒体助力乡村振兴社区教育示范基地，推动当地农村经济发展
      </div>
      <div class="desc">
        党建引领聚合力，携手共建促振兴;创新机制，打造了乡村振兴品牌，围绕凤二村的本土特色，持续开展非遗文创设计，应用于凤二村特色农产品销售，并利用全媒体传播矩阵进行推广，帮助当地村民解决农村全媒体平台运营中的现实问题，推动了当地农村经济的发展，
      </div>
      <div class="items">
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 20px;
            padding: 20px;
            width: 100%;
          "
          class="flex-row self-start group_10 item-wrap"
        >
          <div
            v-for="(item, ind) in others"
            :key="ind"
            @click="toPage(item)"
            class="flex-col section_5 space-y-32 c-cursor"
            style="
              padding: 10px;
              background-color: white;
              flex: 1 0 calc(33.33% - 20px);
              max-width: 300px;
            "
          >
            <div class="flex-col items-start space-y-12">
              <img class="image_4" :src="item.file" style="width: 100%" />
              <span class="font_4 text_6">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
 <script>
import Header from "../../components/header/header.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {
      temp_domain: "",
      obj: "",
      arr: "",
      thanks: [],
      xinde: [],
      certificates: [],
      others: [],
    };
  },
  mounted() {
    this.temp_domain = this.domain;
    console.log("this.temp_domain", this.temp_domain);
    this.getTrainEffect();
  },
  methods: {
    toPage(item) {
      console.log("toPage", item);
      if (item.type == "folder") {
        this.$router.push({
          name: "trainfolder",
          params: { id: item.id },
        });
      } else {
        this.$router.push({
          name: "trainfile",
          params: { id: item.id },
        });
      }
    },
    getTrainEffect() {
      this.http
        .post(this.api.getTrainEffect)
        .then((res) => {
          let { code, data } = res.data;
          if (code == 200) {
            console.log("data", data);
            this.thanks = data.thanks;
            this.xinde = data.xinde;
            this.certificates = data.certificates;
            this.others = data.others;
            console.log("this.thanks", this.thanks);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
   
   <style scoped lang="less">
.article {
  font-size: 30px;
  text-align: center;
}
.section {
  width: 80%;
  margin: 0 auto;
  .title {
    font-size: 30px;
    font-weight: bold;
    // 文字前面加圆点
    &::before {
      content: "•";
      color: hsl(231, 77.8%, 7.1%);
      margin-right: 0.5rem;
    }
  }
  .desc {
    font-size: 20px;
    margin-top: 10px;
    padding-left: 20px;
  }
}

.item {
  // 一行显示3个，使用flex布局
  display: flex;
  justify-content: space-between;
  // 间距
  margin: 0 0.5rem;
  // 宽度
  width: 100%;
  // 高度
  height: 100%;
}
.section_5 {
  padding-bottom: 0;
  background-color: hsl(0, 0%, 96.9%);
  border-radius: 1rem;
  height: auto;
  .space-y-12 {
    & > *:not(:first-child) {
      margin-top: 0.75rem;
    }
    .image_4 {
      border-radius: 1rem 1rem 0px 0px;
      // width: 17.63rem;
      width: 100%;
      height: auto;
    }
    .font_4 {
      font-size: 0.88rem;
      font-family: "PingFang SC";
      line-height: 1.25rem;
      color: hsl(231, 77.8%, 7.1%);
      padding: 0 0.4rem;
    }
    .text_6 {
      margin: 0.5rem auto;
    }
  }
  .font_5 {
    font-size: 0.75rem;
    font-family: "PingFang SC";
    line-height: 1.06rem;
    color: hsla(231, 77.8%, 7.1%, 0.45);
  }
  .text_9 {
    margin-left: 1rem;
  }
}
.space-y-32 {
  & > *:not(:first-child) {
    margin-top: 2rem;
  }
}
</style>
   