<template>
  <div class="flex-col section_6">
    <div class="flex-row items-center group_5 space-x-12">
      <div class="section_5"></div>
      <span class="font_4">热点招聘</span>
    </div>
    <van-field
      class="pos inputValue_iMl"
      placeholder="搜索职位/公司名称"
      :border="false"
      label-width="calc(100% - 56px)"
      input-align="left"
      v-model="params.keywords"
    >
      <template #button>
        <van-button @click="emitParam()" size="small" type="default"
          >找工作</van-button
        >
      </template>
    </van-field>

    <!-- <van-field
      label="搜索职位/公司名称"
      placeholder="找工作"
      :border="false"
      label-width="10em"
      input-align="right"
      class="inputValue_iMl"
      v-model="inputValue_iMl"
    ></van-field> -->
    <div class="flex-row group_6 space-x-30">
      <div :class="[isExpand ? 'expanded' : 'not-expand']" class="flex-row flex-auto item-wrap">
        <span class="font_7">工作地点：</span>
        <span
          class="font_8 text_11"
          :class="[activeProvince == -1 ? 'font_2' : 'font_8']"
          @click="changeProvince(-1)"
          >全国</span
        >
        <span
          v-for="(item, key) in province"
          :key="key"
          :class="[activeProvince == key ? 'font_2' : 'font_8']"
          @click="changeProvince(key)"
          class="font_8 text_12"
          >{{ item.cname }}</span
        >
        <!-- <span class="font_2 text_12">北京</span>
        <span class="font_8 text_13">上海</span>
        <span class="font_8 text_14">广东</span>
        <span class="font_8 text_15">天津</span>
        <span class="font_8 text_16">重庆</span>
        <span class="font_8 text_17">安徽</span>
        <span class="font_8 text_18">浙江</span>
        <span class="font_8 text_19">福建</span>
        <span class="font_8 text_20">黑龙江</span>
        <span class="font_8 text_21">吉林</span>
        <span class="font_8 text_22">山东</span>
        <span class="font_8 text_23">安徽</span>
        <span class="font_8 text_24">浙江</span> -->
      </div>
      <div @click="expand" class="flex-row items-center shrink-0 c-cursor more-btn">
        <span class="font_9">更多</span>
        <img
          :class="[isExpand ? 'icon-expanded' : '']"
          class="shrink-0 image_2"
          src="../../assets/01940a816f9f74f42d129ca88a697a08.png"
        />
      </div>
    </div>
    <div class="flex-col section_8 space-y-8">
      <div class="flex-row items item-wrap">
        <span
          class="font_8"
          @click="changeCity(-1)"
          :class="[activeCity == -1 ? 'font_2' : 'font_8']"
          >不限</span
        >
        <span
          class="font_8"
          :class="[activeCity == index ? 'font_2' : 'font_8']"
          v-for="(item, index) in city"
          :key="index"
          @click="changeCity(index)"
          >{{ item.cname }}</span
        >
        <!-- <span class="font_8">朝阳区</span>
        <span class="font_8">海淀区</span>
        <span class="font_8">大兴区</span>
        <span class="font_8">丰台区</span>
        <span class="font_8">昌平区</span>
        <span class="font_2">东城区</span>
        <span class="font_8">通州区</span>
        <span class="font_8">西城区</span>
        <span class="font_8">顺义区</span>
        <span class="font_8">石景山区</span>
        <span class="font_8">房山区</span>
        <span class="font_8">门头沟区</span>
        <span class="font_8">怀柔区</span>
        <span class="font_8">密云区</span>
        <span class="font_8">延庆区</span>
        <span class="font_8">平谷区</span> -->
      </div>
      <!-- <div class="flex-row space-x-20 item-wrap">
        <span class="font_8">怀柔区</span>
        <span class="font_8">密云区</span>
        <span class="font_8">延庆区</span>
        <span class="font_8">平谷区</span>
      </div> -->
    </div>
    <div class="flex-row group_7 item-wrap">
      <span class="font_7">工作经验：</span>
      <span
        v-for="(item, key) in experience"
        :key="key"
        :class="[activeExperience == key ? 'font_2' : 'font_8']"
        @click="selectExperience(key)"
        class="text_26"
        >{{ item.level }}</span
      >
    </div>
    <div class="flex-row group_8 item-wrap">
      <span class="font_7">学历要求：</span>
      <span
        :class="[activeEducation == key ? 'font_2' : 'font_8']"
        @click="selectEducation(key)"
        class="font_8 text_32"
        v-for="(item, key) in education"
        :key="key"
        >{{ item.level }}</span
      >
    </div>
    <div class="flex-row group_9 item-wrap">
      <span class="font_7">英语程度：</span>
      <span
        v-for="(item, key) in english"
        :class="[activeEnglish == key ? 'font_2' : 'font_8']"
        @click="selectEnglish(key)"
        :key="key"
        class="font_8 text_37"
        >{{ item.level }}</span
      >
    </div>
    <div class="flex-row group_10 space-x-20">
      <div class="flex-row items-center section_9 space-x-4">
        <Select
          @on-select="changeSalary"
          v-model="activeSalary"
          placeholder="薪资待遇"
        >
          <Option v-for="(item, key) in job_salary" :value="key" :key="key"
            >{{ item.min }} - {{ item.max }}</Option
          >
        </Select>
      </div>
      <div class="flex-row items-center section_9 space-x-4">
        <Select
          @on-select="changeJob"
          v-model="activeJob"
          placeholder="工作性质"
        >
          <Option v-for="(item, key) in job_type" :value="key" :key="key">{{
            item.name
          }}</Option>
        </Select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      province_id: 2,
      city_id: "",
      activeProvince: 0,
      activeCity: 0,
      province: [],
      city: [],
      inputValue_iMl: "",
      recommendArr: [],
      article: [],
      education: [],
      experience: [],
      job: [],
      job_city: [],
      job_salary: [],
      job_type: [],
      recommend: [],
      english: [],

      activeExperience: 0,
      activeEducation: 0,
      activeEnglish: 0,
      activeSalary: -1,
      activeJob: -1,

      params: {
        job_city_id: "",
        job_type_id: "",
        experience_id: "",
        english_id: "",
        education_id: "",
        job_salary_id: "",
        keywords: "",

        // "job_city_id": 1,
        // "job_type_id": 1,
        // "experience_id": 1,
        // "english_id": 1,
        // "education_id": 1,
        // "job_salary_id": 1,
        // "keywords": ""
      },
      isExpand: false
    };
  },
  created() {
    this.getRecommend();
    this.getArea();
  },
  mounted() {},
  methods: {
    expand() {
      this.isExpand = !this.isExpand
    },
    getArea() {
      var params = {
        province_id: this.province_id,
      };
      this.http
        .post(this.api.area, params)
        .then((res) => {
          console.log("res", res);
          var { code, data } = res.data;
          var { province, city } = data;
          this.province = province;
          this.city = city;
          this.params.job_city_id = this.city[0].id;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    changeProvince(e) {
      if (e == -1) {
        this.activeProvince = e;
        this.job_city_id = "";
      } else {
        console.log("changeProvince", e);
        this.province_id = this.province[e].id;
        this.activeProvince = e;
        this.getArea();
        var self = this;
        setTimeout(() => {
          self.job_city_id = self.city[0].id;
        }, 2000);
      }
    },
    changeCity(e) {
      if (e == -1) {
        this.activeProvince = e;
        this.job_city_id = this.province_id
      } else {
        console.log("changeCity", e);
        this.activeCity = e;
        this.params.job_city_id = this.city[e].id;
        this.emitParam();
      }
    },
    emitParam() {
      console.log("this.params", this.params);
      this.$emit("onChangeSetting", this.params);
    },
    changeJob(e) {
      console.log("changeJob", e);
      this.activeJob = e.value;
      this.params.job_salary_id = this.job_type[this.activeJob].id;
      this.emitParam();
    },
    changeSalary(e) {
      console.log("changeSalary", e);
      this.activeSalary = e.value;
      this.params.job_salary_id = this.education[this.activeSalary].id;
      this.emitParam();
    },
    selectEnglish(e) {
      this.activeEnglish = e;
      this.params.english_id = this.english[this.activeEnglish].id;
      this.emitParam();
    },
    selectEducation(e) {
      this.activeEducation = e;
      this.params.education_id = this.education[this.activeEducation].id;
      this.emitParam();
    },
    selectExperience(e) {
      this.activeExperience = e;
      this.params.experience_id = this.experience[this.activeExperience].id;
      this.emitParam();
    },
    getRecommend() {
      var params = {};
      this.http
        .post(this.api.recommend, params)
        .then((res) => {
          let { code, data } = res.data;
          let {
            article,
            education,
            english,
            experience,
            job,
            job_city,
            job_salary,
            job_type,
            recommend,
          } = data;
          console.log("getRecommend data", data);
          if (code == 200) {
            this.article = article;
            this.education = education;
            this.english = english;
            this.experience = experience;
            this.job = job;
            this.job_city = job_city;
            this.job_salary = job_salary;
            this.job_type = job_type;
            this.recommend = recommend;

            this.params.job_city_id = 1;
            this.params.job_type_id = this.job_type[this.activeJob].id;
            this.params.experience_id =
              this.experience[this.activeExperience].id;
            this.params.english_id = this.english[this.activeEnglish].id;
            this.params.education_id = this.education[this.activeEducation].id;
            this.params.job_salary_id = this.education[this.activeSalary].id;
            this.params.keywords = "";
            this.emitParam();
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="less">
.image_2 {
  margin-top: 4px;
}
.more-btn {
  align-items: flex-start;
}
.not-expand {
  height: 1.25rem;
  overflow: hidden;
}

.icon-expanded {
  transform: rotate(180deg);
}

.activeProvince {
  color: hsl(233, 88.8%, 61.4%);
}
.section_6 ::v-deep .ivu-select-selection {
  background-color: transparent;
  border: unset;
}
.item-wrap {
  flex-wrap: wrap;
}
.section_6 .inputValue_iMl {
  height: 48px;
  padding: 3px 3px 3px 1rem;
}
.section_6 ::v-deep .van-field__value,
.section_6 ::v-deep .van-field__body,
.section_6 ::v-deep .van-field__button,
.section_6 ::v-deep .van-button--small {
  height: 100%;
}

.section_6 ::v-deep .van-field__control {
  color: rgba(4, 8, 32, 0.65);
}

.section_6 ::v-deep .van-button--default {
  color: #fff;
  font-size: 14px;
  background: #455af4;
  width: 80px;
}

.section_8 .items > span {
  margin-right: 8px;
}
.section_6 {
  padding: 0 1.25rem 1rem;
  background-color: hsl(0, 0%, 100%);
  border-radius: 1rem;
  .group_5 {
    padding: 1.5rem 0 1.19rem;
    .section_5 {
      border-radius: 50%;
      width: 0.75rem;
      height: 0.75rem;
      border: solid 0.19rem hsl(233, 88.8%, 61.4%);
    }
    .font_4 {
      font-size: 1.5rem;
      font-family: "PingFang SC";
      line-height: 2.06rem;
      font-weight: 500;
      color: hsl(231, 77.8%, 7.1%);
    }
  }
  .space-x-12 {
    & > *:not(:first-child) {
      margin-left: 0.75rem;
    }
  }
  .inputValue_iMl {
    background-color: hsla(233, 88.8%, 61.4%, 0.06);
    border: solid 0.063rem hsla(233, 88.8%, 61.4%, 0.35);
    border-radius: 0.5rem;
    // padding: 0.19rem 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .group_6 {
    padding: 1.5rem 0 0.75rem;
    .text_11 {
      margin-left: 0.5rem;
    }
    .text_12 {
      margin-left: 1.25rem;
    }
    .text_13 {
      margin-left: 1.25rem;
    }
    .text_14 {
      margin-left: 1.25rem;
    }
    .text_15 {
      margin-left: 1.25rem;
    }
    .text_16 {
      margin-left: 1.25rem;
    }
    .text_17 {
      margin-left: 1.25rem;
    }
    .text_18 {
      margin-left: 1.25rem;
    }
    .text_19 {
      margin-left: 1.25rem;
    }
    .text_20 {
      margin-left: 1.25rem;
    }
    .text_21 {
      margin-left: 1.25rem;
    }
    .text_22 {
      margin-left: 1.25rem;
    }
    .text_23 {
      margin-left: 1.25rem;
    }
    .text_24 {
      margin-left: 1.25rem;
    }
    .image_2 {
      width: 0.75rem;
      height: 0.75rem;
    }
  }
  .space-x-30 {
    & > *:not(:first-child) {
      margin-left: 1.88rem;
    }
  }
  .section_8 {
    padding: 0.63rem 0.75rem;
    background-color: hsl(0, 0%, 96.9%);
    border-radius: 0.5rem;
    .text_25 {
      margin-right: 2rem;
    }
  }
  .space-y-8 {
    & > *:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
  .font_8 {
    font-size: 0.88rem;
    font-family: "PingFang SC";
    line-height: 1.25rem;
    color: hsla(231, 77.8%, 7.1%, 0.65);
    cursor: pointer;
  }
  .font_2 {
    font-size: 0.88rem;
    font-family: "PingFang SC";
    line-height: 1.25rem;
    color: hsl(233, 88.8%, 61.4%);
  }
  .group_7 {
    margin-top: 1rem;
    .text_26 {
      margin-left: 0.5rem;
    }
    .text_27 {
      margin-left: 1.25rem;
    }
    .text_28 {
      margin-left: 1.25rem;
    }
    .text_29 {
      margin-left: 1.25rem;
    }
    .text_30 {
      margin-left: 1.25rem;
    }
    .text_31 {
      margin-left: 1.25rem;
    }
  }
  .font_7 {
    font-size: 0.88rem;
    font-family: "PingFang SC";
    line-height: 1.25rem;
    font-weight: 500;
    color: hsla(231, 77.8%, 7.1%, 0.85);
  }
  .group_8 {
    margin-top: 1.25rem;
    .text_32 {
      margin-left: 0.5rem;
    }
    .text_33 {
      margin-left: 1.25rem;
    }
    .text_34 {
      margin-left: 1.25rem;
    }
    .text_35 {
      margin-left: 1.25rem;
    }
    .text_36 {
      margin-left: 1.25rem;
    }
  }
  .group_9 {
    margin-top: 1.25rem;
    .text_37 {
      margin-left: 0.5rem;
    }
    .text_38 {
      margin-left: 1.25rem;
    }
    .text_39 {
      margin-left: 1.25rem;
    }
    .text_40 {
      margin-left: 1.25rem;
    }
    .text_41 {
      margin-left: 1.25rem;
    }
    .text_42 {
      margin-left: 1.25rem;
    }
    .text_43 {
      margin-left: 1.25rem;
    }
  }
  .group_10 {
    margin-top: 1.25rem;
    .section_9 {
      // padding: 0.5rem 0.75rem;
      background-color: hsl(0, 0%, 96.9%);
      border-radius: 0.25rem;
      height: 2.25rem;
      .image_3 {
        width: 0.5rem;
        height: 0.5rem;
      }
      .image_4 {
        width: 0.5rem;
        height: 0.25rem;
      }
    }
    .space-x-4 {
      & > *:not(:first-child) {
        margin-left: 0.25rem;
      }
    }
  }
  .space-x-20 {
    & > *:not(:first-child) {
      margin-left: 1.25rem;
    }
  }
  .font_9 {
    font-size: 0.88rem;
    font-family: "PingFang SC";
    line-height: 1.25rem;
    color: hsl(230, 23.5%, 20%);
  }
}
</style>