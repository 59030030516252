<template>
  <div class="flex-col shrink-0" style="width: 260px;">

    <div v-for="(item, key) in categoryArr" :key="key" style="margin-bottom:0.75rem;" v-if="key != 0" @click="navToPage(item)">
      <div class="flex-row items-center space-x-5">
        <span class="font_3">{{ item.category.name }}</span>
        <img class="image_2" src="../../assets/e92df329aa7d9f73db4281a6b732fd05.png" />
      </div>

      <div class="flex-row group_3 space-x-16 item-wrap">
        <span v-for="(itemChil, keyChild) in item.child" :key="keyChild" class="font_2" title="itemChil.name" @click="childNavTo(itemChil,keyChild)">{{ itemChil.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    data() {
      return {
        categoryArr: []
      };
    },
    created() {
      this.getCategory();
    },
    mounted() {

    },
    methods: {
      childNavTo(item,key){
        console.log("item",item,key)
        if(item.name=='培训方案'){
          this.$router.push({
              name: "trainfile",
              params: { id: 1},
            });
        }
        //培训成效 name
        if(item.name=='培训成效'){
          this.$router.push({
              name: "traineffect",
            });
        }
        //名师队伍
        if(item.name=='名师队伍'){
          this.$router.push({
              name: "trainfolder",
              params: { id: 4 },
            });
        }
        //课程标准 49
        if(item.name=='课程标准'){
          this.$router.push({
              name: "trainfolder",
              params: { id: 49 },
            });
        }
        //学员手册 3
        if(item.name=='学员手册'){
          this.$router.push({
              name: "trainfolder",
              params: { id: 3 },
            });
        }
        //精彩回忆 6
        if(item.name=='精彩回忆'){
          this.$router.push({
              name: "trainfolder",
              params: { id: 6 },
            });
        }

      },
      navToPage(item){
        console.log("item",item)
        if(item.category.name=='数字化课程'){
          this.$router.push({ path: 'list/normal/2' });
        }
        if(item.category.name=='培训风采'){
          this.$router.push({ path: 'list/normal/3' });
        }
        if(item.category.name=='就业直通'){
          this.$router.push({ path: 'jobs' });
        }
        if(item.category.name=='媒体宣传'){
          this.$router.push({ path: 'newslist/media/0' });
        }
        // this.$router.push({ path: '/category', query: { id: item.category.id } });
      },
      getCategory() {
        var params = {};
        this.http.post(this.api.category, params)
          .then((res) => {
            let { code, data } = res.data;
            this.categoryArr = [];
            if (code == 200) {
              this.categoryArr = data;
              console.log("getCategory data", data)
            } else {

            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
</script>

<style scoped lang="less">
.item-wrap {
  flex-wrap: wrap;
}
  .space-x-5 {
    & > *:not(:first-child) {
      margin-left: 0.31rem;
    }
  }
  .group_3 {
    margin-top: 0.75rem;
  }
  .space-x-16 {
    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
  .group_4 {
    margin-top: 1.5rem;
  }
  .font_3 {
    font-size: 1rem;
    font-family: 'PingFang SC';
    line-height: 1.38rem;
    font-weight: 500;
    color: hsl(0, 0%, 100%);
  }
  .image_2 {
    width: 0.75rem;
    height: 0.75rem;
  }
  .group_5 {
    margin-top: 0.75rem;
  }
  .font_2 {
    font-size: 0.88rem;
    font-family: 'PingFang SC';
    line-height: 1.25rem;
    color: hsla(0, 0%, 100%, 0.65);
  }
  .font_2 {
    font-size: 0.88rem;
    font-family: 'PingFang SC';
    line-height: 1.25rem;
    color: hsla(0, 0%, 100%, 0.65);
  }
  .group_6 {
    margin-top: 1.5rem;
  }
  .font_3 {
    font-size: 1rem;
    font-family: 'PingFang SC';
    line-height: 1.38rem;
    font-weight: 500;
    color: hsl(0, 0%, 100%);
  }
  .image_2 {
    width: 0.75rem;
    height: 0.75rem;
  }
  .group_7 {
    margin-top: 0.75rem;
  }
  .font_2 {
    font-size: 0.88rem;
    font-family: 'PingFang SC';
    line-height: 1.25rem;
    color: hsla(0, 0%, 100%, 0.65);
  }
  .font_2 {
    font-size: 0.88rem;
    font-family: 'PingFang SC';
    line-height: 1.25rem;
    color: hsla(0, 0%, 100%, 0.65);
  }
  .font_2 {
    font-size: 0.88rem;
    font-family: 'PingFang SC';
    line-height: 1.25rem;
    color: hsla(0, 0%, 100%, 0.65);
  }
  .group_8 {
    margin-top: 1.5rem;
  }
  .font_3 {
    font-size: 1rem;
    font-family: 'PingFang SC';
    line-height: 1.38rem;
    font-weight: 500;
    color: hsl(0, 0%, 100%);
  }
  .image_2 {
    width: 0.75rem;
    height: 0.75rem;
  }
  .group_9 {
    margin-top: 0.75rem;
  }
  .font_2 {
    font-size: 0.88rem;
    font-family: 'PingFang SC';
    line-height: 1.25rem;
    color: hsla(0, 0%, 100%, 0.65);
  }
  .font_2 {
    font-size: 0.88rem;
    font-family: 'PingFang SC';
    line-height: 1.25rem;
    color: hsla(0, 0%, 100%, 0.65);
  }
  .font_2 {
    font-size: 0.88rem;
    font-family: 'PingFang SC';
    line-height: 1.25rem;
    color: hsla(0, 0%, 100%, 0.65);
  }
</style>
