<template>
  <div style="margin-top: 30px;">
    <Page
      :total="paginations.total"

      :page-size="paginations.pageSize"
      :page-size-opts="paginations.pageSizeOpts"
      :current="paginations.current"
      @on-change="changepage"
      @on-page-size-change="pageSizeChange"
    ></Page>
  </div>
</template>

<script>
    export default {
        name: "page",
        props: {
            paginations: {
                type: Object,
                default: {}
            }
        },
        methods: {
            changepage(index) {
                this.$emit("changepage", index);
            },
            pageSizeChange(index) {
                this.$emit("pageSizeChange",index);
            }
        }
    };
</script>

<style>
</style>
