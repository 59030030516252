<template>
  <div class="flex-col page">
    <Header :showSearch="true" :activeNav="0"/>
    <div class="group_3 newsinfo">
      <div class="header">
        <div class="title">{{details.name}}</div>
        <div class="info"><span v-if="details.author">作者：{{details.author}}</span><span>时间：{{details.created_at}}</span></div>
      </div>
      <div class="content">
        <p class="article_text_content" v-html="details.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from '../../components/header/header.vue';
  import HotFilters from '../../components/HotFilters/HotFilters.vue';

  export default {
    components: { Header, HotFilters },
    data() {
      return {
          detailid:this.$route.params.detailid,
          details: ''
      };
    },
    created() {
        this.getDetails();
    },
    mounted() {

    },
    methods: {
        getDetails() {
            var params = {
                id:this.detailid
            };
            this.http.post(this.api.courseDetails, params).then((res) => {
              let {code, data} = res.data;
              this.details = '';
              if (code == 200) {
                  console.log("details", data)
                  this.details = data;
              } else {

              }
            })
            .catch((error) => {
                console.log(error);
            });
        },
    },
  };
</script>

<style scoped lang="less">
.item-wrap {
  flex-wrap: wrap;
}
.page {
  background-color: hsl(216, 23.8%, 95.9%);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .newsinfo{
    max-width: 75rem;
    margin: 2rem auto;
  }
  .header{
    font-size: 1.5rem;
    font-family: PingFang SC;
    line-height: 2.06rem;
    font-weight: 600;
    color: #040820;
    text-align: center;
    padding: 0 1rem;
    .info{
      color: #999;
      font-size: 1rem;
      margin: 1rem auto;
      text-align: center;
      span{
        padding:0 2rem;
      }
    }
  }
  .content{
    width: 100%;
    max-width: 100%;
    font-size: 1.2rem;
    padding: 1rem;
  }
}
</style>
