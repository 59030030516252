<template>
  <div
    :style="cStyle"
    class="flex-row justify-between header-section"
    :class="[activeNav == 1 ? 'has-searchbox' : null]"
  >
    <div class="flex-row items-center self-start group_2 space-x-80">
      <img
        class="image"
        src="../../assets/1e670387ece3f4ee87320ba287cde9e2.png"
         
      />
      <div class="flex-row items-center space-x-48">
        <!--<div class="c-cursor font_1 nav-btn" @click="onClickIndex()">
          <span  class="c-cursor font_1 text">首页</span>
        </div>-->

        <div
          @click="onClick(item)"
          v-for="(item, key) in oneMenu"
          :key="key"
          class="nav-btn flex-col justify-start items-center shrink-0"
        >
          <span class="c-cursor font_1 text">{{ item.name }}</span>
        </div>

        <!-- <div :class="[activeNav == 1 ? 'text-wrapper_2' : null]"
          class="nav-btn flex-col justify-start items-center shrink-0">
          <span class="c-cursor font_1 text" @click="onClick('jobs')">就业直通</span>
        </div>-->
      </div>
    </div>
    <div class="flex-col justify-start items-end relative group">
      <!--<van-field v-if="showSearch" class="inputValue_ZTX" placeholder="搜索课程，教师昵称" :border="false"
        label-width="calc(100% - 56px)" input-align="left" v-model="inputValue_ZTX">
        <template #button>
          <van-button size="small" type="default">搜索</van-button>
        </template>
      </van-field>-->
      <div class="justify-start items-center text-wrapper">
        <div class="log-btn c-cursor font_1"><span>登录</span></div>
        <div class="c-cursor font_1">｜</div>
        <div class="log-btn c-cursor font_1"><span>注册</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    cStyle: {
      type: String,
      default: "",
    },
    activeNav: {
      type: Number,
      default: -1,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue_ZTX: "",
      oneMenu: [],
    };
  },
  created() {
    this.getOenMenu();
  },

  methods: { 
    getOenMenu() {
      var params = {};
      this.http
        .post(this.api.getmenu, params)
        .then((res) => {
          let { code, data } = res.data;
          this.oneMenu = [];
          if (code == 200) {
            this.oneMenu = data;
            console.log("oneMenu data", data);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClick(item) {
      this.$router.push({ name: "index" });
      var reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
      if (!reg.test(item.link)) {
        document.querySelector("#" + item.name).scrollIntoView({
          behavior: "smooth",
        });
        //alert("这网址不是以http://https://开头，或者不是网址！");
      } else {
        window.open(item.link, "_blank");
      }
    },
    onClickIndex() {
      this.$router.push({ name: "index" });
    },
  },
};
</script>

<style scoped lang="less">
.log-btn {
  border-radius: 1.25rem;
  width: 3.5rem;
  height: 2.5rem;
  padding: 0.38rem 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.log-btn:hover {
  background-color: #fff;
  color: rgb(69, 90, 244);
}

.log-btn:hover span {
  color: rgb(69, 90, 244);
}

.nav-btn {
  border-radius: 1.25rem;
  width: 7rem;
  height: 2.5rem;
  padding: 0.38rem 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-btn:hover {
  background-color: #fff;
  color: rgb(69, 90, 244);
}

.nav-btn:hover span {
  color: rgb(69, 90, 244);
}

.header-section .group .inputValue_ZTX {
  height: 2.5rem;
  margin-bottom: 8px;
}

.header-section ::v-deep .van-field__value,
.header-section ::v-deep .van-field__body,
.header-section ::v-deep .van-field__button,
.header-section ::v-deep .van-button--small {
  height: 100%;
}

.header-section ::v-deep .van-field__control {
  color: #fff;
}

.header-section ::v-deep .van-button--default {
  color: #455af4;
  font-size: 14px;
}

.header-section {
  padding: 0 7.5rem;
  overflow: hidden;
  background-color: hsl(233, 88.8%, 61.4%);
  height: 8.75rem;

  .group_2 {
    margin-top: 2.5rem;

    .image {
      width: 17.5rem;
      height: 3.25rem;
    }

    .space-x-48 {
      & > *:not(:first-child) {
        margin-left: 1rem;
      }

      .text-wrapper_2 {
        padding: 0.38rem 0;
        background-color: hsl(0, 0%, 100%);
        border-radius: 1.25rem;
        width: 7rem;
        height: 2.5rem;

        .text {
          color: hsl(233, 88.8%, 61.4%);
        }
      }
    }
  }

  .space-x-80 {
    & > *:not(:first-child) {
      margin-left: 4rem;
    }
  }

  .group {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url("../../assets/e894fd46aa2a819a22190755f304bee9.png");
    background-size: 100% 100%;
    background-position: 223px 0px;
    background-repeat: no-repeat;
    background-size: 65%;

    // margin-bottom: -16.25rem;
    // width: 31.72rem;
    .text-wrapper {
      // padding: 3.25rem 0 20rem;
      // background-image: url('../../assets/e894fd46aa2a819a22190755f304bee9.png');
      // background-size: 100% 100%;
      // width: 18.09rem;
      margin-left: 3rem;
      display: flex;
      padding-bottom: 8px;
    }

    .pos {
      position: absolute;
      left: 0;
      top: 2.88rem;
    }

    .inputValue_ZTX {
      background-color: hsla(0, 0%, 100%, 0.1);
      border: solid 0.063rem hsl(0, 0%, 100%);
      border-radius: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0 0 0.81rem;
      width: 15rem;
    }
  }

  .font_1 {
    font-size: 1.25rem;
    font-family: "PingFang SC";
    line-height: 1.75rem;
    color: hsl(0, 0%, 100%);
  }
}

@media (min-width: 1910px) {
  .header-section {
    padding: 0px 10.5rem;
  }
}

@media (min-width: 2010px) {
  .header-section {
    padding: 0px 14.5rem;
  }
}

@media (min-width: 1175px) {
  .space-x-48 {
    margin-left: 1rem !important;
  }

  .header-section.has-searchbox .group {
    margin-left: 1rem;
  }
}

@media (max-width: 960px) {
  .header-section {
    padding: 0px 3.5rem;
  }
}

@media (max-width: 800px) {
  .header-section .space-x-80 > * {
    margin-left: 1rem !important;
  }
}

@media (max-width: 1175px) {
  .has-searchbox {
    display: block;
  }

  .header-section.has-searchbox .group_2 {
    justify-content: space-between;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .header-section.has-searchbox .group {
    justify-content: space-between;
    background-image: none;
  }
}
@media (max-width: 800px) {
  .header-section .group .text-wrapper {
    margin-left: 1rem !important;
  }

  .header-section {
    display: block;
    padding: 0px 1.5rem;
  }

  .header-section .group_2 {
    justify-content: space-between;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .header-section .group {
    justify-content: flex-end;
    background-image: none;
  }

  .header-section .group_2 .image {
    margin-left: 0 !important;
  }
}

@media (max-width: 600px) {
  .header-section {
    padding: 0px 1.5rem;
  }

  .page .group_3 .section_3 {
    width: 11.5rem;
  }
}

@media (max-width: 575px) {
  .header-section .group_2 .image {
    width: 12.5rem;
  }
}
</style>
