<template>
  <div class="flex-col page">
    <Header :showSearch="true" :activeNav="0" />
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px;
        width: 100%;
      "
      class="flex-row self-start group_10 item-wrap"
      v-for="(ar, index) in arr"
      :key="index"
    >
      <div
        v-for="(item, ind) in ar"
        :key="ind"
        @click="toPage(item)"
        class="flex-col section_5 space-y-32 c-cursor"
        style="
          padding: 10px;
          background-color: white;
          flex: 1 0 calc(33.33% - 20px);
          max-width: 300px;
        "
      >
        <div class="flex-col items-start space-y-12">
          <img class="image_4" :src="item.cover" style="width: 100%;max-height: 156px;" />
          <span class="font_4 text_6">{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
 
 <script>
import Header from "../../components/header/header.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {
      temp_domain: "",
      obj: "",
      arr: "",
    };
  },
  mounted() {
    this.temp_domain = this.domain;
    console.log("this.temp_domain", this.temp_domain);
    this.getTrainList();
  },
  methods: {
    toPage(item) {
      console.log("toPage", item);
      //如果name是培训成效，跳转effect
      if (item.title == "培训成效") {
        this.$router.push({
          name: "traineffect",
        });
      } else {
        //如果type是folder，跳转trainfolder
        if (item.type == "folder") {
          this.$router.push({
            name: "trainfolder",
            params: { id: item.id },
          });
        } else {
          //如果id=5，是培训成效，跳转effect
          if (item.id == 5) {
            this.$router.push({
              name: "traineffect",
            });
          } else {
            //说明是文件
            this.$router.push({
              name: "trainfile",
              params: { id: item.id },
            });
          }
        }
      }
    },
    getTrainList() {
      this.http
        .post(this.api.getTrainList)
        .then((res) => {
          let { code, data } = res.data;
          if (code == 200) {
            //data按照3个分组
            let arr = [];
            let temp = [];
            for (let i = 0; i < data.length; i++) {
              temp.push(data[i]);
              if (temp.length == 3) {
                arr.push(temp);
                temp = [];
              }
            }
            if (temp.length > 0) {
              arr.push(temp);
            }
            console.log("arr", arr);
            this.arr = arr;
            this.obj = data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
   
   <style scoped lang="less">
.item {
  // 一行显示3个，使用flex布局
  display: flex;
  justify-content: space-between;
  // 间距
  margin: 0 0.5rem;
  // 宽度
  width: 100%;
  // 高度
  height: 100%;
}
.section_5 {
  padding-bottom: 0;
  background-color: hsl(0, 0%, 96.9%);
  border-radius: 1rem;
  height: auto;
  .space-y-12 {
    & > *:not(:first-child) {
      margin-top: 0.75rem;
    }
    .image_4 {
      border-radius: 1rem 1rem 0px 0px;
      // width: 17.63rem;
      width: 100%;
      height: auto;
    }
    .font_4 {
      font-size: 0.88rem;
      font-family: "PingFang SC";
      line-height: 1.25rem;
      color: hsl(231, 77.8%, 7.1%);
      padding: 0 0.4rem;
    }
    .text_6 {
      margin: 0.5rem auto;
    }
  }
  .font_5 {
    font-size: 0.75rem;
    font-family: "PingFang SC";
    line-height: 1.06rem;
    color: hsla(231, 77.8%, 7.1%, 0.45);
  }
  .text_9 {
    margin-left: 1rem;
  }
}
.space-y-32 {
  & > *:not(:first-child) {
    margin-top: 2rem;
  }
}
</style>
   