<template>
  <div class="flex-col section_4">
    <div class="flex-row items-center space-x-12">
      <div class="section_5"></div>
      <span class="font_4">求职攻略</span>
    </div>
    <!-- 保持下方2个span的样式，但是要for循环 -->
    <div class="flex-col" v-for="item,key in articles" :key="key">
      <span class="self-start font_5 text_3">{{ item.title }}</span>
      <span class="self-start font_3 text_4">{{ item.description }}</span>
    </div>

    <!-- <span class="self-start font_5 text_5">标题标题标题标题标题标题标题标题标题标题标题标题</span>
    <span class="self-start font_3 text_6">描述描述描述描述描述描述描述描述描述</span>
    <span class="self-start font_5 text_7">标题标题标题标题标题标题标题标题标题标题标题标题</span>
    <span class="self-start font_3 text_8">描述描述描述描述描述描述描述描述描述</span> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    articles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="less">
.section_4 {
  padding: 1.5rem 1.25rem 1.88rem;
  background-color: hsl(0, 0%, 100%);
  border-radius: 1rem;
  .space-x-12 {
    & > *:not(:first-child) {
      margin-left: 0.75rem;
    }
    .section_5 {
      border-radius: 50%;
      width: 0.75rem;
      height: 0.75rem;
      border: solid 0.19rem hsl(233, 88.8%, 61.4%);
    }
    .font_4 {
      font-size: 1.5rem;
      font-family: "PingFang SC";
      line-height: 2.06rem;
      font-weight: 500;
      color: hsl(231, 77.8%, 7.1%);
    }
  }
  .font_5 {
    font-size: 1rem;
    font-family: "PingFang SC";
    line-height: 1.38rem;
    font-weight: 500;
    color: hsl(231, 77.8%, 7.1%);
  }
  .text_3 {
    margin-top: 1.25rem;
  }
  .font_3 {
    font-size: 0.75rem;
    font-family: "PingFang SC";
    line-height: 1.06rem;
    color: hsla(231, 77.8%, 7.1%, 0.65);
  }
  .text_4 {
    margin-top: 0.5rem;
  }
  .text_5 {
    margin-top: 1rem;
  }
  .text_6 {
    margin-top: 0.5rem;
  }
  .text_7 {
    margin-top: 1rem;
  }
  .text_8 {
    margin-top: 0.5rem;
  }
}
</style>