<template>
  <div class="flex-col section_10">
    <div class="flex-row justify-between group_11 item-wrap">
      <div class="flex-col space-y-12">
        <span class="self-start font_5">{{ obj.title }}</span>
        <div class="flex-row">
          <span class="font_10">{{ obj.salary_min ? obj.salary_min : 0 }} - {{ obj.salary_max ? obj.salary_max : '0' }}K</span>
          <div v-for="(item, key) in parseTextArr(obj.tags)" :key="key" class="flex-col justify-start items-center shrink-0 text-wrapper_5">
            <span class="font_3">{{ item }}</span>
          </div>
          <!-- <div class="flex-col justify-start items-center shrink-0 text-wrapper_7 view_13">
            <span class="font_3">大专</span>
          </div>
          <div class="flex-col justify-start items-center shrink-0 text-wrapper_7 view_14">
            <span class="font_3">全职</span>
          </div> -->
        </div>
      </div>
      <div class="flex-row space-x-19">
        <img class="image_5" :src="obj.company.cover" v-if="obj.company" />
        <div class="flex-col space-y-12">
          <span class="self-start font_5" v-if="obj.company">{{obj.company.name}}</span>
          <div class="flex-row space-x-12" v-if="obj.tags">
            <div class="flex-col justify-start items-center text-wrapper_7"><span class="font_3">{{ obj.tags.split(',')[0] }}</span></div>
            <div class="flex-col justify-start items-center text-wrapper_10"><span class="font_3">{{ obj.tags.split(',')[1] }}</span></div>
            <div class="flex-col justify-start items-center text-wrapper_11"><span class="font_3">{{ obj.city.name }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-row section_11 space-x-24">
      <span class="font_11">{{ obj.created_at ? obj.created_at.split('T')[0] : ''}}发布</span>
      <span class="font_11">
        <!-- tags,换成| -->
        {{ obj.tags.replace(/,/g, ' | ') }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      obj: {
        type: Object,
        default: ()=> {
          return {}
        }
      }
    },
    data() {
      return {};
    },
    mounted() {
      console.log("jItem", this.obj)
    },
    methods: {
      parseTextArr(e) {
        console.log("parseTextArr", e.split(","))
        return e.split(",")
      },
    },
  };
</script>

<style scoped lang="less">
.item-wrap {
  flex-wrap: wrap;
}
  .section_10 {
    background-color: hsl(0, 0%, 100%);
    border-radius: 1rem;
    .group_11 {
      padding: 1.19rem 1.25rem 0.94rem;
      .space-y-12 {
        & > *:not(:first-child) {
          margin-top: 0.75rem;
        }
        .font_5 {
          font-size: 1rem;
          font-family: 'PingFang SC';
          line-height: 1.38rem;
          font-weight: 500;
          color: hsl(231, 77.8%, 7.1%);
        }
        .font_10 {
          font-size: 1rem;
          font-family: 'PingFang SC';
          line-height: 1.38rem;
          font-weight: 500;
          color: hsl(6, 79.2%, 62.4%);
        }
        .text-wrapper_5 {
          margin-left: 1.25rem;
          padding: 0.25rem 0;
          background-color: hsl(0, 0%, 96.9%);
          border-radius: 0.25rem;
          width: 4.75rem;
          height: 1.5rem;
        }
        .text-wrapper_7 {
          padding: 0.25rem 0;
          background-color: hsl(0, 0%, 96.9%);
          border-radius: 0.25rem;
          width: 2.5rem;
          height: 1.5rem;
        }
        .view_13 {
          margin-left: 0.75rem;
        }
        .font_3 {
          font-size: 0.75rem;
          font-family: 'PingFang SC';
          line-height: 1.06rem;
          color: hsla(231, 77.8%, 7.1%, 0.65);
        }
        .view_14 {
          margin-left: 0.75rem;
        }
        .space-x-12 {
          & > *:not(:first-child) {
            margin-left: 0.75rem;
          }
          .text-wrapper_10 {
            padding: 0.25rem 0;
            background-color: hsl(0, 0%, 96.9%);
            border-radius: 0.25rem;
            width: 4.81rem;
            height: 1.5rem;
          }
          .text-wrapper_11 {
            padding: 0.25rem 0;
            background-color: hsl(0, 0%, 96.9%);
            border-radius: 0.25rem;
            width: 4.5rem;
            height: 1.5rem;
          }
        }
      }
      .space-x-19 {
        & > *:not(:first-child) {
          margin-left: 1.19rem;
        }
        .image_5 {
          border-radius: 0.5rem;
          width: 3.75rem;
          height: 3.75rem;
        }
      }
    }
    .section_11 {
      padding: 0.75rem 1.25rem;
      background-image: linear-gradient(270deg, hsla(228, 100%, 99%, 0.85) 0%, hsla(231, 87.1%, 93.9%, 0.85) 100%);
      border-radius: 0px 0px 1rem 1rem;
      .font_11 {
        font-size: 0.75rem;
        font-family: 'PingFang SC';
        line-height: 1.06rem;
        color: hsla(231, 77.8%, 7.1%, 0.45);
      }
    }
    .space-x-24 {
      & > *:not(:first-child) {
        margin-left: 1.5rem;
      }
    }
  }
</style>