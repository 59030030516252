import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../pages/index/index.vue';
import Jobs from '../pages/jobs/jobs.vue';
import news from '../pages/news/news.vue';
import coursedetails from '../pages/details/courseDetails.vue';
import remmberdetails from '../pages/details/remmberDetails.vue';
import list from '../pages/details/list.vue';
import newslist from '../pages/news/newslist.vue';
import listdetails from '../pages/details/llistDetails.vue';
import file from '../pages/train/file.vue';
import folder from '../pages/train/folder.vue';
import home from '../pages/train/home.vue';
import effect from '../pages/train/effect.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: Jobs,
  },{
    path: '/news/:newId',
    name: 'news',
    component: news,
  },{
    path: '/coursedetails/:detailid',
    name: 'coursedetails',
    component: coursedetails,
  },{
    path: '/remmberdetails/:detailid',
    name: 'remmberdetails',
    component: remmberdetails,
  },{
    path: '/list/:type/:cid',
    name: 'list',
    component: list,
  },{
    path: '/newslist/:type/:cid',
    name: 'newslist',
    component: newslist,
  },{
    path: '/listdetails/:type/:detailid',
    name: 'listdetails',
    component: listdetails,
  },
  {
    path:'/file/:id',
    name:"trainfile",
    component:file
  },
  {
    path:'/folder/:id',
    name:"trainfolder",
    component:folder
  },
  {
    path:'/train',
    name:"train",
    component:home
  },
  {
    path:'/traineffect',
    name:"traineffect",
    component:effect
  },

];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
