<template>
  <div class="flex-col page">
    <Header :showSearch="true" :activeNav="1" />
    <div class="flex-row group_3">
      <!-- <Recommends :recommends="recommends" class="section_3" /> -->
      <JobCategory
        :recommends="jobCategories"
        class="section_3"
        style="margin-top: 24px"
        @search="searchCategory"
      />
      <div class="flex-col flex-auto self-start group_1">
        <!-- <JobSearchStrategy :articles="article"/> -->
        <HotFilters
          @onChangeSetting="onChangeSetting($event)"
          class="section_1"
        />
        <div class="flex-col group_13">
          <!-- <div class="flex-col section_10">
            <div class="flex-row justify-between group_11 item-wrap">
              <div class="flex-col space-y-12">
                <span class="self-start font_5">外贸业务员【北京-东城区】</span>
                <div class="flex-row">
                  <span class="font_10">11-18K</span>
                  <div class="flex-col justify-start items-center shrink-0 text-wrapper_5">
                    <span class="font_3">应届毕业生</span>
                  </div>
                  <div class="flex-col justify-start items-center shrink-0 text-wrapper_7 view_13">
                    <span class="font_3">大专</span>
                  </div>
                  <div class="flex-col justify-start items-center shrink-0 text-wrapper_7 view_14">
                    <span class="font_3">全职</span>
                  </div>
                </div>
              </div>
              <div class="flex-row space-x-19">
                <img class="image_5" src="../../assets/bdf1c7a585e52b78baba877973406944.png" />
                <div class="flex-col space-y-12">
                  <span class="self-start font_5">北京城优</span>
                  <div class="flex-row space-x-12">
                    <div class="flex-col justify-start items-center text-wrapper_7">
                      <span class="font_3">外贸</span>
                    </div>
                    <div class="flex-col justify-start items-center text-wrapper_10">
                      <span class="font_3">100-500人</span>
                    </div>
                    <div class="flex-col justify-start items-center text-wrapper_11">
                      <span class="font_3">广东-广州</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row section_11 space-x-24">
              <span class="font_11">2022-07-09发布</span>
              <span class="font_11">外贸｜市场｜业务</span>
            </div>
          </div> -->
          <JItem
            v-for="(item, key) in joblistArr"
            :key="key"
            :obj="item"
            class="view_21"
          />
          <!-- <div class="flex-col section_10 view_18">
            <div class="flex-row justify-between group_11 item-wrap">
              <div class="flex-col space-y-12">
                <span class="self-start font_5">外贸业务员【北京-东城区】</span>
                <div class="flex-row">
                  <span class="font_10">11-18K</span>
                  <div class="flex-col justify-start items-center shrink-0 text-wrapper_5">
                    <span class="font_3">应届毕业生</span>
                  </div>
                  <div class="flex-col justify-start items-center shrink-0 text-wrapper_7 view_13">
                    <span class="font_3">大专</span>
                  </div>
                  <div class="flex-col justify-start items-center shrink-0 text-wrapper_7 view_14">
                    <span class="font_3">全职</span>
                  </div>
                </div>
              </div>
              <div class="flex-row space-x-19">
                <img class="image_5" src="../../assets/bdf1c7a585e52b78baba877973406944.png" />
                <div class="flex-col space-y-12">
                  <span class="self-start font_5">北京城优</span>
                  <div class="flex-row space-x-12">
                    <div class="flex-col justify-start items-center text-wrapper_7">
                      <span class="font_3">外贸</span>
                    </div>
                    <div class="flex-col justify-start items-center text-wrapper_10">
                      <span class="font_3">100-500人</span>
                    </div>
                    <div class="flex-col justify-start items-center text-wrapper_11">
                      <span class="font_3">广东-广州</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row section_11 space-x-24">
              <span class="font_11">2022-07-09发布</span>
              <span class="font_11">外贸｜市场｜业务</span>
            </div>
          </div>
          <div class="flex-col section_10 view_19">
            <div class="flex-row justify-between group_11 item-wrap">
              <div class="flex-col space-y-12">
                <span class="self-start font_5">外贸业务员【北京-东城区】</span>
                <div class="flex-row">
                  <span class="font_10">11-18K</span>
                  <div class="flex-col justify-start items-center shrink-0 text-wrapper_5">
                    <span class="font_3">应届毕业生</span>
                  </div>
                  <div class="flex-col justify-start items-center shrink-0 text-wrapper_7 view_13">
                    <span class="font_3">大专</span>
                  </div>
                  <div class="flex-col justify-start items-center shrink-0 text-wrapper_7 view_14">
                    <span class="font_3">全职</span>
                  </div>
                </div>
              </div>
              <div class="flex-row space-x-19">
                <img class="image_5" src="../../assets/bdf1c7a585e52b78baba877973406944.png" />
                <div class="flex-col space-y-12">
                  <span class="self-start font_5">北京城优</span>
                  <div class="flex-row space-x-12">
                    <div class="flex-col justify-start items-center text-wrapper_7">
                      <span class="font_3">外贸</span>
                    </div>
                    <div class="flex-col justify-start items-center text-wrapper_10">
                      <span class="font_3">100-500人</span>
                    </div>
                    <div class="flex-col justify-start items-center text-wrapper_11">
                      <span class="font_3">广东-广州</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-row section_11 space-x-24">
              <span class="font_11">2022-07-09发布</span>
              <span class="font_11">外贸｜市场｜业务</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header/header.vue";
import HotFilters from "../../components/HotFilters/HotFilters.vue";
import JItem from "../../components/jItem/jItem.vue";
import JobSearchStrategy from "../../components/JobSearchStrategy/JobSearchStrategy.vue";
// import Recommends from '../../components/recommends/recommends.vue';
import JobCategory from "../../components/jobCategory/jobCategory.vue";

export default {
  components: { Header, HotFilters, JItem, JobSearchStrategy, JobCategory },
  data() {
    return {
      joblistArr: [],
      recommends: [],
      jobCategories: [],
      article: [],
      currentJobCategoryId: "",
      currentParams: ""
    };
  },
  created() {},
  mounted() {
    this.getRecommend();
    var params = {
        "job_city_id": 1,
        "job_type_id": 1,
        "experience_id": 1,
        "english_id": 1,
        "education_id": 1,
        "job_salary_id": 1,
        "keywords": ""
      };
    this.getJobList(params);
    //监听search的事件
  },
  methods: {
    searchCategory(e) {
      console.log("searchCategory", e);
      localStorage.setItem("currentJobCategoryId", e.id);
      this.currentJobCategoryId = e.id;
      //如果currentParams为空字符串，则使用默认
      this.currentParams = this.currentParams || {
        job_city_id: "",
        job_type_id: "",
        experience_id: "",
        english_id: 1,
        education_id: "",
        job_salary_id: "",
        keywords: "",
      };
      this.getJobList(this.currentParams);
    },
    getRecommend() {
      var params = {};
      this.http
        .post(this.api.recommend, params)
        .then((res) => {
          let { code, data } = res.data;
          let {
            article,
            education,
            english,
            experience,
            job,
            job_city,
            job_salary,
            job_type,
            recommend,
          } = data;
          console.log("getRecommend data", data);
          if (code == 200) {
            this.article = article;
            this.recommends = recommend;
            this.jobCategories = data.job_category;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onChangeSetting(e) {
      this.currentParams = e;
      this.getJobList(e);
    },
    getJobList(e) {
      // var params = {
      //   "job_city_id": 1,
      //   "job_type_id": 1,
      //   "experience_id": 1,
      //   "english_id": 1,
      //   "education_id": 1,
      //   "job_salary_id": 1,
      //   "keywords": ""
      // };
      e.category_id = this.currentJobCategoryId;
      this.http
        .post(this.api.jobList, e)
        .then((res) => {
          let { code, data } = res.data;
          this.joblistArr = [];
          if (code == 200) {
            console.log("getJobList data", data);
            this.joblistArr = data.job;
          } else {
          }
          console.log("getJobList", this.joblistArr);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="less">
.item-wrap {
  flex-wrap: wrap;
}
.page {
  background-color: hsl(216, 23.8%, 95.9%);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  .group_3 {
    padding: 0 7.5rem;
    .section_3 {
      flex-shrink: 0;
    }
    .group_1 {
      margin-left: 1.5rem;
      .section_1 {
        margin-top: 1.5rem;
      }
      .group_13 {
        margin-top: 1rem;
        .section_10 {
          background-color: hsl(0, 0%, 100%);
          border-radius: 1rem;
          .group_11 {
            padding: 1.19rem 1.25rem 0.94rem;
            .space-y-12 {
              & > *:not(:first-child) {
                margin-top: 0.75rem;
              }
              .font_5 {
                font-size: 1rem;
                font-family: "PingFang SC";
                line-height: 1.38rem;
                font-weight: 500;
                color: hsl(231, 77.8%, 7.1%);
              }
              .font_10 {
                font-size: 1rem;
                font-family: "PingFang SC";
                line-height: 1.38rem;
                font-weight: 500;
                color: hsl(6, 79.2%, 62.4%);
              }
              .text-wrapper_5 {
                margin-left: 1.25rem;
                padding: 0.25rem 0;
                background-color: hsl(0, 0%, 96.9%);
                border-radius: 0.25rem;
                width: 4.75rem;
                height: 1.5rem;
              }
              .text-wrapper_7 {
                padding: 0.25rem 0;
                background-color: hsl(0, 0%, 96.9%);
                border-radius: 0.25rem;
                width: 2.5rem;
                height: 1.5rem;
              }
              .view_13 {
                margin-left: 0.75rem;
              }
              .font_3 {
                font-size: 0.75rem;
                font-family: "PingFang SC";
                line-height: 1.06rem;
                color: hsla(231, 77.8%, 7.1%, 0.65);
              }
              .view_14 {
                margin-left: 0.75rem;
              }
              .space-x-12 {
                & > *:not(:first-child) {
                  margin-left: 0.75rem;
                }
                .text-wrapper_10 {
                  padding: 0.25rem 0;
                  background-color: hsl(0, 0%, 96.9%);
                  border-radius: 0.25rem;
                  width: 4.81rem;
                  height: 1.5rem;
                }
                .text-wrapper_11 {
                  padding: 0.25rem 0;
                  background-color: hsl(0, 0%, 96.9%);
                  border-radius: 0.25rem;
                  width: 4.5rem;
                  height: 1.5rem;
                }
              }
            }
            .space-x-19 {
              & > *:not(:first-child) {
                margin-left: 1.19rem;
              }
              .image_5 {
                border-radius: 0.5rem;
                width: 3.75rem;
                height: 3.75rem;
              }
            }
          }
          .section_11 {
            padding: 0.75rem 1.25rem;
            background-image: linear-gradient(
              270deg,
              hsla(228, 100%, 99%, 0.85) 0%,
              hsla(231, 87.1%, 93.9%, 0.85) 100%
            );
            border-radius: 0px 0px 1rem 1rem;
            .font_11 {
              font-size: 0.75rem;
              font-family: "PingFang SC";
              line-height: 1.06rem;
              color: hsla(231, 77.8%, 7.1%, 0.45);
            }
          }
          .space-x-24 {
            & > *:not(:first-child) {
              margin-left: 1.5rem;
            }
          }
        }
        .view_21 {
          margin-top: 1rem;
        }
        .view_18 {
          margin-top: 1rem;
        }
        .view_19 {
          margin-top: 1rem;
        }
      }
    }
  }

  .group_3 {
    margin-top: 1.75rem;
  }
}

@media (max-width: 2010px) {
}

@media (max-width: 960px) {
  .page .group_3 {
    padding: 0 3.5rem;
  }
}

@media (max-width: 900px) {
  .page .group_3 {
    padding: 0 1rem;
  }
}
</style>