<template>
  <div class="flex-col page">
    <Header :showSearch="true" :activeNav="0" />
    <div
      @click="clickItem(obj)"
      class="flex-col section_5 space-y-32 c-cursor"
      :title="obj.title"
    >
      <div class="flex-col items-start space-y-12">
        <span class="font_4 text_6" style="font-size: 32px;padding-top: 10px;">{{ obj.title }}</span>
        <!-- 横向居中office -->
        <div class="flex-col" style="width: 100%; height: 100vh">
          <vue-office-pdf
            v-if="obj.file_ext == 'pdf'"
            :src="obj.file"
            @rendered="rendered"
          />
          <vue-office-docx
            v-if="obj.file_ext == 'doc' || obj.file_ext == 'docx'"
            :src="obj.file"
            style="height: 100%; margin: 0; padding: 0"
            @rendered="rendered"
          />

          <vue-office-excel
            v-if="obj.file_ext == 'xls' || obj.file_ext == 'xlsx'"
            @rendered="rendered"
            @error="errorHandler"
            style="height: calc(100vh - 90px)"
          />

          <!-- 如果是图片，显示img -->
          <img
            v-if="obj.file_ext == 'jpg' || obj.file_ext == 'jpeg' || obj.file_ext == 'png'"
            :src="obj.file??obj.cover"
            style="width: 80%; margin-left: 10%;margin-top: 60px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header/header.vue";

import VueOfficePdf from "@vue-office/pdf";
import VueOfficeExcel from "@vue-office/excel";
import VueOfficeDocx from "@vue-office/docx";
import "@vue-office/docx/lib/index.css";
import "@vue-office/excel/lib/index.css";

export default {
  components: {
    VueOfficeExcel,
    VueOfficeDocx,
    VueOfficePdf,
    Header,
  },
  data() {
    return {
      temp_domain: "",
      obj: "",
    };
  },
  mounted() {
    this.temp_domain = this.domain;
    console.log("this.temp_domain", this.temp_domain);
    this.getDetail();
  },
  methods: {
    errorHandler(e) {
      console.log("errorHandler", e);
    },
    rendered() {
      console.log("rendered");
    },
    getDetail() {
      var params = {
        id: this.$route.params.id,
      };
      console.log("params", params);
      this.http
        .post(this.api.getTrainDetail, params)
        .then((res) => {
          let { code, data } = res.data;
          if (code == 200) {
            this.obj = data;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickItem(item) {
      console.log("clickItem", item);
      //如果包含file_ext字段，说明是文件
      if (item.file_ext) {
        //说明是培训风采类型的内容
        if (
          item.file.length > 0 &&
          item.file != undefined &&
          item.file != null
        ) {
          //说明是文件
        } else {
          //说明是目录
        }
      } else {
        if (item.type == 1) {
          window.open(item.link, "_blank");
        } else {
          this.$router.push({
            name: "coursedetails",
            params: { detailid: item.id },
          });
        }
      }
    },
  },
};
</script>
  
  <style scoped lang="less">
.section_5 {
  padding-bottom: 0;
  background-color: hsl(0, 0%, 96.9%);
  border-radius: 1rem;
  height: auto;
  .space-y-12 {
    & > *:not(:first-child) {
      margin-top: 0.75rem;
    }
    .image_4 {
      border-radius: 1rem 1rem 0px 0px;
      // width: 17.63rem;
      width: 100%;
      height: auto;
    }
    .font_4 {
      font-size: 0.88rem;
      font-family: "PingFang SC";
      line-height: 1.25rem;
      color: hsl(231, 77.8%, 7.1%);
      padding: 0 0.4rem;
    }
    .text_6 {
      margin: 0.5rem auto;
    }
  }
  .font_5 {
    font-size: 0.75rem;
    font-family: "PingFang SC";
    line-height: 1.06rem;
    color: hsla(231, 77.8%, 7.1%, 0.45);
  }
  .text_9 {
    margin-left: 1rem;
  }
}
.space-y-32 {
  & > *:not(:first-child) {
    margin-top: 2rem;
  }
}
</style>
  