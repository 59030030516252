export const api = {
    index: "api/index",
    getmenu: "api/getOneMenu",
    getRemmber: "api/getRemmber",
    remmberDetails: "api/remmberDetails",
    getMedia: "api/getMedia",
    mediaDetails: "api/mediaDetails",
    getFreind: "api/getFreind",
    category: "api/category",
    courseDetails: "api/courseDetails",
    recommend: "api/recommend",
    jobList: "api/jobList",
    area:"api/area",
    moreList:"api/moreList",
    listDetails:"api/listDetails",
    getTrainList:"api/getTrainList",
    getTrainDetail:"api/getTrainDetail",
    getTrainFolder:"api/getTrainFolder",
    getTrainEffect:"api/getTrainEffect",
};
