import axios from "axios";

var instance = axios.create({
    baseURL: "https://school.growingsale.cn/",
    // baseURL: "http://localhost:8001/",
    timeout: 30000
});

class http {
    static async get(url, params) {
        return await instance.get(url, { params });
    }

    static async post(url, params) {
        return await instance.post(url, params);
    }
}

export default http;