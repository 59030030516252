import Vue from 'vue';
import App from './app.vue';
import router from './router';

// reset css
import "./resources/styles/reset.css"
// fonts
import "./resources/styles/fonts.scss"

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

import { locale } from 'view-design';
import lang from 'view-design/dist/locale/en-US';
locale(lang);
Vue.use(ViewUI);

import http from "./http-lib/http-common";
import { api } from "./http-lib/api";
Vue.prototype.http = http;
Vue.prototype.api = api;
Vue.prototype.domain = 'school.growingsale.cn';

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
